<template>
  <section class="privacy-policy">
    <h1 class="privacy-policy__title">Polityka Prywatności</h1>

    <div class="privacy-policy__content">
      <h2 class="privacy-policy__subtitle"><strong>1. Informacje ogólne</strong></h2>
      <ol class="privacy-policy__list">
        <li>
          Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url:
          <b>crossfitblackstar.com.pl</b>
        </li>
        <li>
          Operatorem serwisu oraz Administratorem danych osobowych jest: Artur Aleksiewicz
          53-343 Wrocław ul.Komandorska 18
        </li>

        <li>Adres kontaktowy poczty elektronicznej operatora: info@cfblackstar.com</li>

        <li>
          Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych
          podanych dobrowolnie w Serwisie.
        </li>
        <li>Serwis wykorzystuje dane osobowe w następujących celach:</li>
        <ul class="privacy-policy__list privacy-policy__list--unnumber-list">
          <li>Obsługa zapytań przez formularz</li>
          <li>Realizacja zamówionych usług</li>
          <li>Prezentacja oferty lub informacji</li>
        </ul>
        <li>
          Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich
          zachowaniu w następujący sposób:
          <ol class="privacy-policy__list privacy-policy__list--sub-list">
            <li>
              Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają
              wprowadzone do systemów Operatora.
            </li>
            <li>
              Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw.
              „ciasteczka”).
            </li>
          </ol>
        </li>
      </ol>
      <h2 class="privacy-policy__subtitle">
        <strong>2. Wybrane metody ochrony danych stosowane przez Operatora</strong>
      </h2>
      <ol class="privacy-policy__list">
        <li>
          Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie
          transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania,
          wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika i mogą być
          odczytane jedynie na docelowym serwerze.
        </li>
        <li>
          Dane osobowe przechowywane w bazie danych są zaszyfrowane w taki sposób, że
          jedynie posiadający Operator klucz może je odczytać. Dzięki temu dane są
          chronione na wypadek wykradzenia bazy danych z serwera.
        </li>
      </ol>
      <h2 class="privacy-policy__subtitle"><strong>3. Hosting</strong></h2>
      <ol class="privacy-policy__list">
        <li>
          Serwis jest hostowany (technicznie utrzymywany) na serwera operatora: domeny.pl
        </li>
        <li>
          Dane rejestrowe firmy hostingowej: H88 S.A. z siedzibą w Poznaniu, Franklina
          Roosevelta 22, 60-829 Poznań, wpisana do Krajowego Rejestru Sądowego przez Sąd
          Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu, Wydział VIII Gospodarczy
          Krajowego Rejestru Sądowego pod nr KRS 0000612359, REGON 364261632, NIP
          7822622168, kapitał zakładowy 210.000,00 zł w pełni wpłacony.
        </li>
        <li>
          Pod adresem
          <text-links
            classes="privacy-policy__link"
            :IsExternalLink="true"
            href="https://domeny.pl"
          >
            https://domeny.pl
          </text-links>
          możesz dowiedzieć się więcej o hostingu i sprawdzić politykę prywatności firmy
          hostingowej.
        </li>
      </ol>
      <h2 class="privacy-policy__subtitle">
        <strong
          >4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych</strong
        >
      </h2>
      <ol class="privacy-policy__list">
        <li>
          W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe
          innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy
          lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich
          grup odbiorców:
          <ul class="privacy-policy__list privacy-policy__list--unnumber-list">
            <li>
              upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w celu
              realizacji celu działania strony
            </li>
          </ul>
        </li>
        <li>
          Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż jest to
          konieczne do wykonania związanych z nimi czynności określonych osobnymi
          przepisami (np. o prowadzeniu rachunkowości). W odniesieniu do danych
          marketingowych dane nie będą przetwarzane dłużej niż przez 3 lata.
        </li>
        <li>
          Przysługuje Ci prawo żądania od Administratora:
          <ul class="privacy-policy__list privacy-policy__list--unnumber-list">
            <li>dostępu do danych osobowych Ciebie dotyczących,</li>
            <li>ich sprostowania,</li>
            <li>usunięcia,</li>
            <li>ograniczenia przetwarzania,</li>
            <li>oraz przenoszenia danych.</li>
          </ul>
        </li>
        <li>
          Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w
          pkt 3.3 c) wobec przetwarzania danych osobowych w celu wykonania prawnie
          uzasadnionych interesów realizowanych przez Administratora, w tym profilowania,
          przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia
          ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie
          interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub obrony
          roszczeń.
        </li>
        <li>
          Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych
          Osobowych, ul. Stawki 2, 00-193 Warszawa.
        </li>
        <li>
          Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu.
        </li>
        <li>
          W stosunku do Ciebie mogą być podejmowane czynności polegające na
          zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu świadczenia
          usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora
          marketingu bezpośredniego.
        </li>
        <li>
          Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu przepisów o
          ochronie danych osobowych. Oznacza to, że nie przesyłamy ich poza teren Unii
          Europejskiej.
        </li>
      </ol>
      <h2 class="privacy-policy__subtitle">
        <strong>5. Informacje w formularzach</strong>
      </h2>
      <ol class="privacy-policy__list">
        <li>
          Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane
          osobowe, o ile zostaną one podane.
        </li>
        <li>
          Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres
          IP).
        </li>
        <li>
          Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie
          danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W
          takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony
          zawierającej formularz.
        </li>
        <li>
          Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji
          konkretnego formularza, np. w celu dokonania procesu obsługi zgłoszenia
          serwisowego lub kontaktu handlowego, rejestracji usług itp. Każdorazowo kontekst
          i opis formularza w czytelny sposób informuje, do czego on służy.
        </li>
      </ol>

      <h2 class="privacy-policy__subtitle">
        <strong>6. Istotne techniki marketingowe</strong>
      </h2>
      <ol class="privacy-policy__list">
        <li>
          Operator stosuje techniki remarketingowe, pozwalające na dopasowanie przekazów
          reklamowych do zachowania użytkownika na stronie, co może dawać złudzenie, że
          dane osobowe użytkownika są wykorzystywane do jego śledzenia, jednak w praktyce
          nie dochodzi do przekazania żadnych danych osobowych od Operatora do operatorom
          reklam. Technologicznym warunkiem takich działań jest włączona obsługa plików
          cookie.
        </li>
      </ol>

      <h2 class="privacy-policy__subtitle">
        <strong>7. Informacja o plikach cookies</strong>
      </h2>
      <ol class="privacy-policy__list">
        <li>Serwis korzysta z plików cookies.</li>
        <li>
          Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności
          pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu
          i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies
          zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas
          przechowywania ich na urządzeniu końcowym oraz unikalny numer.
        </li>
        <li>
          Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki
          cookies oraz uzyskującym do nich dostęp jest operator Serwisu.
        </li>
        <li>
          Pliki cookies wykorzystywane są w następujących celach:
          <ol class="privacy-policy__list privacy-policy__list--sub-list">
            <li>
              utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której
              użytkownik nie musi ponownie wpisywać danych w formularzu kontaktowym;
            </li>
            <li>
              realizacji celów określonych powyżej w części "Istotne techniki
              marketingowe";
            </li>
          </ol>
        </li>
        <li>
          W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne”
          (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są
          plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika
          do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia
          oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane
          są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików
          cookies lub do czasu ich usunięcia przez Użytkownika.
        </li>
        <li>
          Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa)
          zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu
          końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym
          zakresie.&nbsp;Przeglądarka internetowa umożliwia usunięcie plików cookies.
          Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje
          na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.
        </li>
        <li>
          Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności
          dostępne na podstronach Serwisu.
        </li>
        <li>
          Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu
          wykorzystywane mogą być również przez współpracujące z operatorem Serwisu
          podmioty, w szczególności dotyczy to firmy Google (Google Inc. z siedzibą w
          USA).
        </li>
      </ol>
      <h2 class="privacy-policy__subtitle">
        <strong
          >8. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?</strong
        >
      </h2>
      <ol class="privacy-policy__list">
        <li>
          Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia
          przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla
          procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika
          może utrudnić,&nbsp;a w skrajnych przypadkach może uniemożliwić korzystanie ze
          stron www
        </li>
        <li>
          W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę
          internetową, której używasz i postępuj zgodnie z instrukcjami:
          <ul class="privacy-policy__list privacy-policy__list--unnumber-list">
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history"
              >
                Edge
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer"
              >
                Internet Explorer
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647"
              >
                Chrome
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://support.apple.com/kb/PH5042"
              >
                Safari
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek"
              >
                Firefox
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://help.opera.com/Windows/12.10/pl/cookies.html"
              >
                Opera
              </text-links>
            </li>
          </ul>
          <p>Urządzenia mobilne:</p>
          <ul class="privacy-policy__list privacy-policy__list--unnumber-list">
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647"
              >
                Android
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL"
              >
                Safari (iOS)
              </text-links>
            </li>
            <li>
              <text-links
                href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings"
                classes="privacy-policy__link"
                :IsExternalLink="true"
              >
                Windows Phone
              </text-links>
            </li>
          </ul>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  beforeRouteLeave(_, __, next) {
    if (this.$store.getters.isPhoneMenuOpen) {
      this.$store.commit("openClosePhoneMenu");
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "./scss/privacy-policy.scss";
</style>
